.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Media Query skeleton for common ranges */

/* @media screen and (min-width: 481px) {
  
}

@media screen and (min-width: 768px) {
  
}

@media screen and (min-width: 1025px) {
  
}

@media screen and (min-width: 1281px) {
  
} */


/* Universal CSS */

html{
  font-size: 16px;
  /* height: calc(100vh - calc(100vh - 100%)); */
  height: 100%;
  /* height: -webkit-fill-available; */
}

/* Using postcss-100vh-fix accredited from https://github.com/postcss/postcss-100vh-fix/blob/master/README.md */
/* body{
  height: 100vh;
} */

/* Using postcss-100vh-fix accredited from https://github.com/postcss/postcss-100vh-fix/blob/master/README.md */
/* @supports (-webkit-touch-callout: none){
  body{ */
    /* Safari fix */
    /* height: -webkit-fill-available;
  }
} */

body, #root{
  height: 100%;
}

.page{
  height: 100%;
  display: flex;
  flex-direction: column;
  background-position: top center;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.content{
  flex: 1 0 auto;
}

/* .page{
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
} */

@media screen and (min-width: 481px) and (max-width: 767px) {
  html{
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  html{
    font-size: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  html{
    font-size: 22px;
  }
}

@media screen and (min-width: 1281px){
  html{
    font-size: 24px;
  }
}

a{
  text-decoration: none !important;
}

a:hover{
  color: white !important;
}

p{
  padding: 0;
  margin: 0;
}

/* Navbar CSS */

.nav-bg{
  position: relative;
  width: 100%;
  top: 0;
  background-color: rgb(0, 51, 25);
}

.nav-icon{
  color: white;
  font-size: 2rem;
  padding-top: 1rem;
}

.close{
  display: none;
}

.open{
  display: block;
}

.nav-flex-col{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(0, 51, 25);
}

.nav-flex-row{
  display: flex;
  flex-direction: row;
}

.nav-flex-item{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.nav-flex-item-col{
  display: flex;
  height: 4rem;
  width: 100%;
  background-color: rgb(0, 51, 25);
}

.nav-flex-item-row{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 25%;
  background-color: rgb(0, 51, 25);
}

.nav-flex-item-toggle{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 25%;
  background-color: rgb(0, 51, 25);
}

.nav-flex-item-col:hover{
  background-color: grey;
}

.nav-flex-item-row:hover{
  background-color: grey;
}

.nav-icon-div-col{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon-div-row{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-toggle-link:hover{
  cursor: pointer;
}

.nav-link{
  color: white;
  font-size: 2rem;
}

.nav-text-div-row{
  display: none;
}

.nav-text-div-col{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.text-close{
  display: none;
}

.text-open{
  display: block;
}

/* Navbar Mobile CSS */

.nav-mobile-flex-col{
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 51, 25);
}

.nav-mobile-flex-row{
  display: flex;
  flex-direction: row;
}

.nav-mobile-flex-item{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 25%;
  background-color: rgb(0, 51, 25);
}

.nav-mobile-flex-item-text{
  height: 4rem;
  width: 75%;
  background-color: rgb(0, 51, 25);
}

.nav-mobile-icon{
  color: white;
  font-size: 2rem;
}

.nav-mobile-item-text{
  padding-top: 0.5rem;
  color: white;
  font-size: 2rem;
}

.nav-mobile-item-text-hide{
  padding-top: 0.5rem;
  padding-left: -0.5rem;
  color: rgb(0, 51, 25);
  font-size: 2rem;
}

.nav-mobile-links-div{
  width: 75%;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 51, 25);
  padding-left: -1rem;
}

.nav-mobile-close{
  display: none;
}

.nav-mobile-open{
  display: flex;
}

.nav-mobile-link-a-close{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-mobile-link-a-open{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-mobile-link-a-text{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
}

/* Splash Page CSS */

.splash-text{
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  padding-top: 1rem;
  text-shadow: 0 0 0.25em black;
}

.splash-img{
  height: 14rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Page CSS */

.page-title{
  font-size: 3.5rem;
  text-align: center;
}

.proj-title{
  margin: 1rem;
  font-size: 2rem;
  text-align: center;
}

.flex-all-proj-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-all-proj-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-proj-mobile{
  margin: 1rem;
  padding: 1rem;
  width: 80%;
  background-color: white;
  text-align: center;
}

.flex-proj{
  margin: 1rem;
  padding: 1rem;
  width: 60%;
  background-color: white;
  text-align: center;
}

.resume-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

/* Footer CSS */

footer{
  width: 100%;
  background: transparent;
}

.foot-flex-col{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.foot-flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.foot-flex-col-icon-row{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
}

.foot-flex-col-icon-row-item{
  display: flex;
  width: 50%;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.fa-stack{
  width: 50% !important;
}

@media screen and (min-width: 1025px) {
  .fa-stack{
    width: 30% !important;
  } 
}

.foot-flex-row-icon-row{
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.foot-flex-col-text-col{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
}

.foot-flex-row-text-col{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
}

.proj-gif{
  width: 95%;
  height: 90%;
}

.foot-text{
  color: white;
  font-size: 1rem;
  text-align: center;
}

.foot-text-hide{
  color: transparent;
  font-size: 1rem;
  text-align: center;
}

.foot-logo-link{
  text-decoration: none;
  color: white;
}

.fa-linkedin{
  color: rgb(14,118,168);
}

.fa-envelope-square{
  color: #147efb;
}

.fa-square{
  font-size: 3.4rem;
  padding-left: 0.1em;
}

.foot-logo{
  text-decoration: none;
  font-size: 4rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
